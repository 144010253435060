import axios, { AxiosResponse } from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;
const responseBody = (response: AxiosResponse) => response.data;
import { LOCALDMS_TOKEN } from '../constants';

export const apiClient = axios.create({
    baseURL: `${apiUrl}`,
    headers: { 
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_SUBSCRIPTION_KEY ?? ''
 }
});

export const get = async <T>(url: string, options = {}) => {
    return apiClient.get(url, {
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem(LOCALDMS_TOKEN)}`
          },
        ...options
    }).then(responseBody).then((res: T) => res);
};

export const post = async <T>(url: string, data = {}, options = {}) => {
    return apiClient.post(url, data, options).then(responseBody).then((res: T) => res);
};

export const updateApi = async <T>(url: string, data = {}, options = {}) => {
    return apiClient.post(url, data, {
        ...options,
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem(LOCALDMS_TOKEN)}`
          },
    }).then(responseBody).then((res: T) => res);
};

export const deleteApi = async <T>(url: string, data = {}) => {
    return apiClient.delete(url, data).then(responseBody).then((res: T) => res);
};