import { InteractionRequiredAuthError } from '@azure/msal-browser';

import { loginRequest, msalInstance } from '../configuration';

export const getAccessToken = async (): Promise<string | undefined> =>
  msalInstance
    .acquireTokenSilent(loginRequest)
    .then(({ accessToken }) => accessToken)
    .catch(async (error) => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        await msalInstance.acquireTokenRedirect(loginRequest);
      }
      return undefined;
    });
