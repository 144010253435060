import { Action } from "./../actions/unitMeasureAction";
import { ActionType } from "../action-types";
import { IUnitMeasureInteface } from "../actions/unitMeasureAction";

const initialState = {
    loading: false,
    error:null,
    unitMeasureOptions:[]
}

export const unitMeasurereducer = (state: IUnitMeasureInteface = initialState,
    action: Action)
    : IUnitMeasureInteface => {
    const { type } = action;
    switch (type) {
        case ActionType.FETCH_UNITMEASURE: return {
            loading: true, error: null, unitMeasureOptions: []
        }
        case ActionType.FETCH_UNITMEASURE_ERROR: return {
            loading: false, error: action.payload, unitMeasureOptions: []
        }
        case ActionType.UPDATE_UNITMEASURE_OPTIONS: return {
            loading: false, error: null, unitMeasureOptions: action.payload
        }
        default: return state;
    }

}

export default unitMeasurereducer;