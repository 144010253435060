import { Action } from "./../actions/feedstockAction";
import { ActionType } from "../action-types";
import { FeedstockInteface } from "../actions/feedstockAction";

const initialState = {
    loading: false,
    error:null,
    feedStockOptions:[],
    feedStockValues: []
}

export const feedStockreducer = (state: FeedstockInteface = initialState,
    action: Action)
    : FeedstockInteface => {
    const { type } = action;
    
    switch (type) {
        case ActionType.FETCH_FEEDSTOCK: return {
            ...state,
            loading: true, error: null, feedStockOptions: [],
            feedStockValues : []
        }
        case ActionType.SET_FEEDSTOCK_TABLE : return {
            ...state,
            feedStockValues: action.payload
        }
        case ActionType.FETCH_FEEDSTOCK_ERROR: return {
            ...state,
            loading: false, error: action.payload, feedStockOptions: []
        }
        case ActionType.UPDATE_FEEDSTOCK_OPTIONS: return {
            ...state,
            loading: false, error: null, feedStockOptions: action.payload
        }
        default: return state;
    }

}

export default feedStockreducer;