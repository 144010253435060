export enum ActionType {
    FETCH_FEEDSTOCK = "fetch_feedstock",
    FETCH_FEEDSTOCK_ERROR = "fetch_feedstock_error",
    UPDATE_FEEDSTOCK_OPTIONS = 'update_feedstock_options',
    FETCH_UNITMEASURE = "fetch_unitmeasure",
    FETCH_UNITMEASURE_ERROR = "fetch_unitmeasure_error",
    UPDATE_UNITMEASURE_OPTIONS = 'update_unitmeasure_options',
    FETCH_TECHNOLOGYPROVIDER = "fetch_technologyProvider",
    FETCH_TECHNOLOGYPROVIDER_ERROR = "fetch_technologyProvider_error",
    UPDATE_TECHNOLOGYPROVIDER_OPTIONS = 'update_technologyProvider_options',
    FETCH_KNOWNPRODUCTS = "fetch_knownProducts",
    FETCH_KNOWNPRODUCTS_ERROR = "fetch_knownProducts_error",
    UPDATE_KNOWNPRODUCTS_OPTIONS = 'update_knownProducts_options',
    SET_TABLE_DATA = 'set_table_data',
    UPDATE_TABLE_DATA = 'update_table_data',
    DELETE_RECORD = 'delete_record',
    SET_SELECTED_RECORD = 'set_selected_record',
    SET_FEEDSTOCK_TABLE = 'set_feedstock_table',
    SET_ALL_RECORDS = 'set_all_records',
    FETCH_ALL_RECORDS = 'fetch_all_records',
    SETLOADING = 'setloading',
    FETCH_ADMIN_TABLE = 'fetch_admin_table',
    REMOVELOADING = 'removeloading',
    RECORD_SUBMITTED = 'record_submitted',
    SET_TABLE_COUNT = 'set_table_count',
    MAP_FILES = 'map_files',
    UPDATED_TRANSACTIONAL_DATA = 'updated_transactional_data',
    ADMIN_LOADING_HIDE = 'admin_loading_hide',
    ADMIN_LOADING_SHOW = 'admin_loading_show',
    ADMIN_TABLE_COUNT = 'admin_table_count',
    RESET_TRANSACTIONAL_PARAMS = 'reset_transactional_params'
}
