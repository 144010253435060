import { useState, useEffect } from 'react';
import { Text, NavBar, Pecten, Badge, Icons, Tooltip, ButtonLink } from '@sede-x/shell-ds-react-framework';
import { useNavigate } from 'react-router-dom';
import { useUserProfile } from '../../hooks/useUserProfile';
import IconsComponent from '../IconsComponent';
import { USERROLES } from '../../constants';
import { AvatarWithTextStyled, ToggleStyled, AppTitle, NotificationsMenu, NotificationsMenuItem } from './style';

interface Props {
    readonly onDarkModeChange?: (isChecked: boolean) => void;
}

function Header({ onDarkModeChange }: Props) {
    const navigate = useNavigate();
    const userProfile = useUserProfile();
    const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
    useEffect(() => {
        const theme = localStorage.getItem("siteTheme");
        setIsDarkMode(theme === "dark");
    }, []);

    const handleDarkModeChange = (checked: boolean) => {
        if (onDarkModeChange) {
            onDarkModeChange(checked);
        }
        setIsDarkMode(checked);
        localStorage.setItem("siteTheme", checked ? "dark" : "light");
    };

    const renderNotificationIcon = () => {
        return <NotificationsMenu onClick={() => navigate('/notifications')}>
            <NotificationsMenuItem>
                <Tooltip
                    overlay={'View Notification'}
                    placement="top"
                >
                    <Badge
                        state='warning'
                        icon={IconsComponent({ icon: <Icons.BellAlert />, hideIcon: false, iconClickHandler: () => navigate('/notifications') })}
                    >
                    </Badge>
                </Tooltip>
            </NotificationsMenuItem>
        </NotificationsMenu >
    }

    const renderDashboard = () => {
        return <NotificationsMenu onClick={() => navigate('/dashboard')}>
            <NotificationsMenuItem>
                <Tooltip
                    overlay={'View Dashboard'}
                    placement="top"
                >
                    <Badge
                        state='warning'
                        icon={IconsComponent({ icon: <Icons.BarChartSolid />, hideIcon: false, iconClickHandler: () => navigate('/dashboard') })}
                    >
                    </Badge>
                </Tooltip>
            </NotificationsMenuItem>
        </NotificationsMenu >
    }

    const renderUserManual = () => {
        return <ButtonLink size="large" iconOnly={true} icon={<Icons.FilePdfSolid />} onClick={() => window.open(`${process.env.REACT_APP_SDMS_USERMANUAL}`)}></ButtonLink>
    }

    return (<NavBar float={true}
        pectenArea={<><Pecten />
            <AppTitle onClick={() => navigate("/")}>
                <Text>
                    Low Carbon Fuel Asset and Capacity Database
                </Text>
            </AppTitle>
        </>}
        avatarArea={<>{renderDashboard()}{userProfile?.roles?.includes(`${USERROLES.ADMIN}`) ? renderNotificationIcon() : ''}
            {onDarkModeChange && <ToggleStyled size='small' label="Dark Mode" labelPosition="right" onChange={handleDarkModeChange} checked={isDarkMode} />}
            {renderUserManual()}
            <AvatarWithTextStyled label={!userProfile?.roles?.includes(`${USERROLES.ADMIN}`) ? `${userProfile.name}` : `${userProfile.name}-Admin`} additionalText={userProfile.email} />
        </>}
    />);
}

export default Header;