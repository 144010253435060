import { combineReducers } from "redux";
import feedStockreducer from "./feedStock";
import unitMeasurereducer from "./unitMeasure";
import technologyProviderReducer from "./technologyProvider";
import knownProductsrreducer from "./knownProduct";
import adminActionsReducer from "./adminActions";
import tableDataReducer from './tableData';

const reducers = combineReducers({
    feedStockreducer,
    unitMeasurereducer,
    technologyProviderReducer,
    knownProductsrreducer,
    adminActionsReducer,
    tableDataReducer
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;