import { Loading, Text } from '@sede-x/shell-ds-react-framework';
import { FC } from 'react';

import styled from "styled-components";

type AuthLoaderProps = {
  readonly message?: string;
};


export const StyledLoad = styled.div`
  background:  ${(props) => props.theme.background.surface};
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  && svg {margin-right: 10px;}
`;

export const AuthLoader: FC<AuthLoaderProps> = () => (
  <StyledLoad>
    <Loading size="normal" />
    <Text size="medium"> Authenticating user...</Text>
  </StyledLoad>
);
