import {Alert, Heading, Text} from '@sede-x/shell-ds-react-framework';
import React, { FC } from 'react';

export const Unauthorized: FC = () => (
  <div style={{width: "50%", margin: "auto", paddingTop: "50px"}}>
    <Alert state="error">
      <Heading type="h3">Access Denied</Heading>
      <Text>
        You are not authorized to access this application.
      </Text>
    </Alert>
  </div>
);
