import {
  ProtocolMode,
  BrowserCacheLocation,
  PublicClientApplication,
  Configuration,
} from '@azure/msal-browser'

const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
    authority: process.env.REACT_APP_SSO_URL as string,
    knownAuthorities: [process.env.REACT_APP_SSO_URL as string],
    protocolMode: ProtocolMode.OIDC,
    redirectUri: process.env.REACT_APP_REDIRECT_URL as string,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: false,
  },
}

export const loginRequest = {
  scopes: ['openid', 'profile', 'email', 'roles'],
}

export const msalInstance = new PublicClientApplication(msalConfig)
