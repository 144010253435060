
import { ReactElement } from 'react';
interface IconsComponentProps {
    icon: ReactElement,
    iconClickHandler?: (e: React.MouseEvent<HTMLDivElement>) => void
    hideIcon: boolean
}
const IconsComponent: React.FC<IconsComponentProps> = ({ icon, iconClickHandler, hideIcon = true }) => {
    return (
        <div style={{cursor: "pointer", paddingTop: '5px', paddingLeft : '2px'}} onClick={iconClickHandler}>
            { hideIcon ? null : icon}
        </div>
    )
}
export default IconsComponent;