import styled from 'styled-components';
import { Grid, Heading, Badge, Tabs, Modal, Checkbox, TextInput } from '@sede-x/shell-ds-react-framework';


export const Container = styled.div`
    max-width: 75%;
    margin: 0 auto;
`;

export type FontSize = 'Small' | 'Medium' | 'Large';

export const BreadCrumbWrapper = styled.div <{ size?: string }>`
        padding-bottom: 20px;
        & .shell-breadcrumb{
            font-size: ${props => props.size ? props.size : '24px'} !important;
        }
        & svg{
            margin-top: 4px;
            width: 24px !important;
            height: 24px !important;
        }
    `;

export const Table = styled.table`
        border-collapse: separate;
        border-spacing: 20px;
        width: 100%;
        & td:nth-child(2) {
            float: right;
      }
    `;

export type StatBoxState = 'Neutral' | 'Negative';

export const StatBox = styled(Grid) <{ state?: StatBoxState }>`
    box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);
    border: 0.5px solid #babdc1;
    background: ${props => props.state === 'Negative' ? 'rgb(175 38 64 / 20%)' : 'rgb(59 89 135 / 20%)'};
    :hover {
        border: 0.5px solid lightgray;
        box-shadow: 0 4px 12px 0 rgb(34 36 38 / 12%), 0 4px 12px 0 rgb(34 36 38 / 15%);
    }
    `;

export const StatBody = styled.div`
    padding: 20px;
    cursor: pointer;
`;

export const StatTitle = styled.div`
    letter-spacing: 0.05em;
    font-size: 20px;
`;

export const StatValue = styled.div`
     padding-top: 90px;
     padding-bottom: 10px;
     font-size: 3em;
     font-Weight: bold;
`;

export const StatusBadge = styled(Badge) <{width?: string }>`
       width: ${props => props.width ? props.width : ''};
       & span {padding: 5px;}
    `;

export const SearchFormWrapper = styled.div`
    width: 70%;
 `;

 export const ModalStyled = styled(Modal)`
 & .shell-modal-container {
     width: 50% !important; 
     height: 500px;
 }`;
 
export const StyledTabs = styled(Tabs)`
& .shell-tabs-nav {
    cursor:pointer;

    & .shell-tabs-nav-wrap{
        width:100%;
        & .shell-tabs-tab-btn {
            font-size: 18px;
         }
    }
 }

 & .shell-tabs-tab-active {
    background: ${(props) => props.theme.background.surface};
    
    border-radius: 10px !important;
 }
  & .shell-tabs-content-holder {
    margin-top: 10px;
    padding: 10px;
 }
`;

export const StyledHeading = styled(Heading)`
    padding-bottom: 7px;
    padding-top: 7px;
    `;

export const StyledGrid = styled(Grid)`
    padding:10px;
    box-shadow: rgba(34, 36, 38, 0.12) 0px 2px 4px 0px, rgba(34, 36, 38, 0.15) 0px 2px 10px 0px;
    background: rgba(59, 89, 135, 0.2);
    border-radius: 5px;
    `;

export const StyledCheckbox = styled(Checkbox)<{ disabled:boolean }>`

    & .shell-checkbox-label{
        color: ${props => props.disabled ? 'rgba(64,64,64,1)' : ''};
    }
    `;

export const ErrorMessage = styled.div`
    color: red;
    font-size:16px;
    font-style: italic;
    `;

export const ListItem = styled.li`
    list-style:none;
    max-height: 25px;
    display:flex;
    align-items: center;
    border-radius: 5px;
    padding:2px 0px;
    margin: 20px 0px;
`;

export const StyledText = styled(TextInput)`
    border-radius: 5px;
    cursor: pointer;
`;

export const StyledAnchor = styled.a`
    width: 100%;
    text-decoration: none;
    cursor: pointer;
`;

export const ButtonGroup = styled.div`
        display: flex;
        & .shell-button:nth-child(2) {
            margin-left: 20px;
  } `;

