import { Action } from "./../actions/tableAction";
import { ActionType } from "../action-types";
import { ITableDataProps } from "../actions/tableAction";
import { ITableRowData } from "../../interfaces";

const initialState = {
    activeRecords:[],
    allRecords: [],
    selectedRecord: {} as ITableRowData,
    knownProductsToMap: [],
    feedstocksToMap:[],
    techProviderdsToMap: [],
    unitMeasureToMap:[],
    totalRecords: 0,
    carbonSourceToMap :[],
    hydrogenToMap:[],
    filesToMap: [],
    changedTransactionalData : [],
    recordSubmitted: null,
    loading: {
        show: false,
        text: ''
    },
}

export const tableDataReducer = (state: ITableDataProps = initialState,
    action: Action)
    : ITableDataProps => {
    const { type } = action;
    switch (type) {
        case ActionType.SETLOADING : return {
            ...state,
            ...state.loading,
            loading: { text:action.payload, show: true}
        }
        case ActionType.REMOVELOADING : {
            return {
            ...state,
            ...state.loading,
            loading: { text:'', show: false }
        }
    }
        case ActionType.SET_TABLE_DATA: return {
            ...state,
            activeRecords: action.payload
        }
        case ActionType.FETCH_ALL_RECORDS: return {
            ...state,
            allRecords: []
        }
        case ActionType.SET_TABLE_COUNT: return {
            ...state,
            totalRecords: action.payload
        }
        case ActionType.SET_ALL_RECORDS: return {
            ...state,
            allRecords: action.payload
        }
        case ActionType.SET_SELECTED_RECORD: return {
            ...state,
            selectedRecord: action.payload 
        }
        case ActionType.MAP_FILES: {
            return {
            ...state,
            filesToMap: action.payload
        }}
        case ActionType.RECORD_SUBMITTED: {
            return {
                ...state,
                recordSubmitted: action.payload
            }
        }
        case ActionType.UPDATED_TRANSACTIONAL_DATA: return {
            ...state,
            changedTransactionalData: [...state.changedTransactionalData, action.payload]
        }
        case ActionType.RESET_TRANSACTIONAL_PARAMS: return {
            ...state,
            changedTransactionalData: []
        }
        default: return state;
    }

}

export default tableDataReducer;