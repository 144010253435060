import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider, IMsalContext } from '@azure/msal-react';
import { useEffect, ElementType, FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthLoader, Unauthorized } from './components';
import { loginRequest, msalInstance } from './configuration';
import { NavigationClient } from './msal-utils';

export const AuthenticationProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const navigationClient = new NavigationClient(navigate);

    msalInstance.setNavigationClient(navigationClient);
  }, [navigate]);

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        loadingComponent={AuthLoader as ElementType<IMsalContext>}
        errorComponent={Unauthorized}
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
      >
        {children}
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};
