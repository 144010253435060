import { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { ShellThemeProvider, ThemeMode, Grid } from '@sede-x/shell-ds-react-framework';
import Router from './router/AppRouter';
import Header from './components/Header';
import GlobalErrorHandler from './components/GlobalErrorHandler';
import { post } from './utils/apiHandler';
import { LOCALDMS_TOKEN } from './constants';
import { Provider } from 'react-redux';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './AppInsights';
import { store } from './state';
import { AuthenticationProvider } from './auth';

const PageContainer = styled(Grid)`
  background: ${(props) => props.theme.background.surface};
`;

const Page = styled.div`
  display: flex;
  margin-top: -12px;
  min-height: 94.3vh;
`;

const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    min-width: 400px;
  }
  &&&.Toastify__toast-container--top-right {
    top: 3em;
  }
`;

const Content = styled.div`
  background: ${(props) => props.theme.background.base};
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 35px;
`;
interface GetAccessTokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
}

const refreshTime = 890000; //890 seconds

function App() {
  const [siteTheme, setSiteTheme] = useState<ThemeMode>('light');
  const onDarkModeChange = (isSelected: boolean) => {
    setSiteTheme(isSelected ? 'dark' : 'light');
  };

  useEffect(() => {
    const theme = localStorage.getItem('siteTheme');
    setSiteTheme(theme === 'dark' ? 'dark' : 'light');
    getAccessToken();
    const intervalCallToken = setInterval(() => {
      getAccessToken();
    }, refreshTime);
    return () => {
      // clean up
      clearInterval(intervalCallToken);
    };
  }, []);

  const getAccessToken = async () => {
    const reqBody: Record<string, string> = {
      client_id: process.env.REACT_APP_APIM_CLIENTID || '',
      client_secret: process.env.REACT_APP_APIM_CLIENTSECRET || '',
      grant_type: 'client_credentials',
      scope: 'ais-lcf-supply',
    };
    await post<GetAccessTokenResponse>('lcf-supplydata/token.oauth2', JSON.stringify(reqBody)).then((data) => {
      const { access_token } = data;
      window.localStorage.setItem(LOCALDMS_TOKEN, access_token);
    });
  };

  return (
    <ShellThemeProvider theme={siteTheme}>
      <Provider store={store}>
        <BrowserRouter>
          <GlobalErrorHandler>
            <AuthenticationProvider>
              <AppInsightsContext.Provider value={reactPlugin}>
                <PageContainer>
                  <Header onDarkModeChange={onDarkModeChange} />
                  <Page>
                    <Content>
                      <StyledToastContainer position="top-right" />
                      <Router />
                    </Content>
                  </Page>
                </PageContainer>
              </AppInsightsContext.Provider>
            </AuthenticationProvider>
          </GlobalErrorHandler>
        </BrowserRouter>
      </Provider>
    </ShellThemeProvider>
  );
}

export default App;
