export const LOCALSTORAGE_CODE_VERIFIER = 'code_verifier';
export const LOCALSTORAGE_ACCESS_TOKEN = 'access_token';
export const LOCALSTORAGE_REFRESH_TOKEN = 'refresh_token';
export const LOCALSTORAGE_PREVIOUS_ROUTE = 'previous_route';
export const LOCALSTORAGE_USER_ROLES = 'user_roles';
export const LOCALSTORAGE_NOTIFICATIONS = 'notifications';
export const LOCALDMS_TOKEN = 'dms_token';
export const LOCALSTORAGE_USER = 'user';
export const USERROLES = {
  READONLYUSER: 'LCF_SDB_AppAccess_' + process.env.REACT_APP_ENV,
  SUPERUSER: 'LCF_SDB_SuperUser_' + process.env.REACT_APP_ENV,
  ADMIN: 'LCF_SDB_Admin_' + process.env.REACT_APP_ENV,
  STANDARDUSER: 'LCF_SDB_LimitedUser_' + process.env.REACT_APP_ENV,
};
export const LOCALSTORAGE_PAGE_HAS_BEEN_FORCE_REFRESHED = 'PageHasBeenForceRefreshed';
