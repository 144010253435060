import React,{ Component, ErrorInfo } from 'react';
import ErrorMessageView from './ErrorMessageView';
import { LOCALSTORAGE_PAGE_HAS_BEEN_FORCE_REFRESHED} from '../constants';

interface ErrorboundaryProps {
    children: React.ReactElement ;
}

interface ErrorBoundaryState {
    hasError: boolean;
    error: string;
    info: string;
    uuid: string;
}

const retryPageLoading = () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
        window.localStorage.getItem(LOCALSTORAGE_PAGE_HAS_BEEN_FORCE_REFRESHED) ?? "false"
    ) as boolean;

    if (!pageHasAlreadyBeenForceRefreshed) {
        window.localStorage.setItem(LOCALSTORAGE_PAGE_HAS_BEEN_FORCE_REFRESHED, "true");
        return window.location.reload();
    }
    window.localStorage.setItem(LOCALSTORAGE_PAGE_HAS_BEEN_FORCE_REFRESHED, "false");
};

export default class GlobalErrorHandler extends Component<ErrorboundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorboundaryProps) {
        super(props);
        this.state = {
            hasError: false,
            error: '',
            info: '',
            uuid: '',
        };
    }

    componentDidCatch(error: Error, info: ErrorInfo) {

        const errorMessage = error.message;
        const uuidRef = window.crypto.randomUUID();
        const errorInfo = `${JSON.stringify(error, Object.getOwnPropertyNames(error))} at ${JSON.stringify(info)}`;

        // Display fallback UI
        this.setState({
            hasError: true,
            error: errorMessage,
            info: errorInfo,
            uuid: uuidRef,
        });

        if (errorMessage.indexOf("Loading chunk") > -1) {
            retryPageLoading();
        }
    }

    render() {
        if (this.state.hasError) {
            return (<ErrorMessageView error={this.state.error} info={this.state.info} uuid={this.state.uuid} />
            );
        }
        return this.props.children;
    }
}
