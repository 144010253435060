import { Action, ITableData } from "../actions/adminAction";
import { ActionType } from "../action-types";

const initialState = {
    adminTable: [],
    totalRecords: 0,
    loading: {
        show: false,
        text: ''
    }
}

export const adminActionsReducer = (state: ITableData = initialState,
    action: Action)
    : ITableData => {
    const { type } = action;
    switch (type) {
        case ActionType.FETCH_ADMIN_TABLE : return {
            ...state,
            adminTable: action.payload
        }
        case ActionType.ADMIN_TABLE_COUNT : return {
            ...state,
            totalRecords: Number(action.payload)
        }
        case ActionType.ADMIN_LOADING_HIDE  : {
            return {
            ...state,
            ...state.loading,
            loading: { text: '', show: false }
            }
        }
        case ActionType.ADMIN_LOADING_SHOW : return {
            ...state,
           ...state.loading,
           loading : { text: action.payload, show: true }
        }

        default: return state;
    }

}

export default adminActionsReducer;