import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const connectionStr = `InstrumentationKey=a5b997b6-8d44-4c43-ac45-38cbaa207916;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=178ef8d3-ab79-4ed2-9111-562cc650779c`;
const appInsights = new ApplicationInsights({
    config: {
        connectionString: connectionStr,
        extensions: [reactPlugin],
    }
});
appInsights.loadAppInsights();
appInsights.trackPageView();

const userDetails = localStorage.getItem('user');

if (userDetails) {
  try {
    appInsights.trackEvent({
      name: 'USER DETAILS',
      properties: { user: userDetails },
    });
  } catch (error) {
    console.error('Failed to track event:', error);
  }
}

export { reactPlugin, appInsights };