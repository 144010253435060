import styled from 'styled-components';
import { AvatarWithText, Toggle } from '@sede-x/shell-ds-react-framework';

export const NotificationsMenu = styled.div`
    padding-left: 10px;
    color: rgba(117,117,117,1);
    font-size: 16px !important;
    font-weight: bold !important;
    cursor: pointer;
    `;

export const NotificationsMenuItem = styled.div`
    display: flex;
    align-items: center;
    && { 
        .shell-badge {margin-left: 10px; padding-left: 5px; padding-right: 10px;}
        svg { margin: 2px;}
        }
    `;

export const ToggleStyled = styled(Toggle)`
    padding-right: 20x;
    padding-left: 20px;
    `;

export const AppTitle = styled.div`
    cursor: pointer;
    padding-left: 20px;
    & p {
        letter-spacing: 0.05em;
        font-size: 20px;
    }
    p: hover {
        color: gray;
    }
    `;

export const AvatarWithTextStyled = styled(AvatarWithText)`
    padding-right: 20px;
    & .shell-icon {margin: 5px !important;}
    `;