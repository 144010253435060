import { Action, KnownProductInteface } from "./../actions/knownProductsAction";
import { ActionType } from "../action-types";

const initialState = {
    loading: false,
    error:null,
    knownProductsOptions:[]
}

export const knownProductsrreducer = (state: KnownProductInteface = initialState,
    action: Action)
    : KnownProductInteface => {
    const { type } = action;
    switch (type) {
        case ActionType.FETCH_KNOWNPRODUCTS: return {
            loading: true, error: null, knownProductsOptions: []
        }
        case ActionType.FETCH_KNOWNPRODUCTS_ERROR: return {
            loading: false, error: action.payload, knownProductsOptions: []
        }
        case ActionType.UPDATE_KNOWNPRODUCTS_OPTIONS: return {
            loading: false, error: null, knownProductsOptions: action.payload
        }
        default: return state;
    }

}

export default knownProductsrreducer;