import React, { ReactElement, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Text } from '@sede-x/shell-ds-react-framework';
import routepaths from './routepaths';
import { useUserProfile } from '../hooks/useUserProfile';
import { USERROLES } from '../constants';

const HomePage = React.lazy(() => import('../pages/Home'));
const AdminNotifications = React.lazy(() => import('../pages/AdminNotifications'));

function AppRouter(): ReactElement {
    const userProfile = useUserProfile();

    return (
        <Suspense fallback={<Text>Loading...</Text>}>
            <Routes>
                {userProfile?.isAuthenticated && userProfile?.roles?.includes(`${USERROLES.ADMIN}`) &&
                    <Route path={routepaths.notifications} element={<AdminNotifications />} />
                }
                <Route path={routepaths.home} element={<HomePage />} />
                <Route path={routepaths.dashboard} element={
                    <iframe title="Supply Database Dashboard" width="100%" height="100%"
                        src="https://app.powerbi.com/reportEmbed?reportId=6a43a16f-4ea5-4efb-b9a4-050b04067265&appId=65c7e730-1d12-4263-bd33-0ecc646b4b16&autoAuth=true&ctid=db1e96a8-a3da-442a-930b-235cac24cd5c"
                        allowFullScreen={true}></iframe>
                } />
            </Routes>
        </Suspense>
    );
}

export default AppRouter;
