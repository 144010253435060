import { Action, TechnologyProviderInteface } from "./../actions/technologyProviderActions";
import { ActionType } from "../action-types";

const initialState = {
    loading: false,
    error:null,
    technologyProviderOptions:[]
}

export const technologyProviderReducer = (state: TechnologyProviderInteface = initialState,
    action: Action)
    : TechnologyProviderInteface => {
    const { type } = action;
    switch (type) {
        case ActionType.FETCH_TECHNOLOGYPROVIDER: return {
            loading: true, error: null, technologyProviderOptions: []
        }
        case ActionType.FETCH_TECHNOLOGYPROVIDER_ERROR: return {
            loading: false, error: action.payload, technologyProviderOptions: []
        }
        case ActionType.UPDATE_TECHNOLOGYPROVIDER_OPTIONS: return {
            loading: false, error: null, technologyProviderOptions: action.payload
        }
        default: return state;
    }

}

export default technologyProviderReducer;