import type { AuthenticationResult, EventMessage } from '@azure/msal-browser'
import { EventType } from '@azure/msal-browser'

import { msalInstance } from '../configuration'

export const configureMsalInstance = async (): Promise<void> => {
  await msalInstance.initialize();


  const accounts = msalInstance.getAllAccounts()

  if (accounts[0] !== undefined) {
    msalInstance.setActiveAccount(accounts[0])
  }

  msalInstance.addEventCallback((event: EventMessage) => {

    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult
      const { account } = payload
      msalInstance.setActiveAccount(account)
    }
  })
}
